import React, { useEffect, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { observer } from 'mobx-react';

import app from 'State/AppService';
import workflow from 'State/WorkflowService';
import errorHandler from 'State/ErrorHandler';
import { conditionalStyles } from 'Helpers/conditionalStyles';
import DefaultLayout from 'Components/Workflow/DefaultLayout';
import Headline from 'Components/Workflow/Headline';
import PepText from 'Components/PepText';
import Nav from 'Components/Workflow/Nav';
import Cart from 'Components/Workflow/Cart';
import { PayloadInsurance } from 'Models/UI/PayloadInsurance';
import { BookingItem } from 'Models/UI/BookingItem';
import { BookingItemInterface } from 'Models/UI/BookingItemInterface';
import { Insurance as InsuranceItem } from 'Models/UI/Insurance';
import { Keys } from 'Translation/Setup';

import { Box } from './Components/Box';
import { Info } from './Components/Info';
import { insuranceState, InsuranceState } from './InsuranceState';
import pepxpressStyles from './Insurance-pepxpress.module.scss';
import pepxciteStyles from './Insurance-pepxcite.module.scss';
import iconHanse from './Icons/logo-hansemerkur-s.png';
import Breadcrumbs from '../../../Components/Layouts/Parts/Breadcrumbs';
import { withTheme } from '../../../Context/withTheme';
import { ThemeProps } from '../../../Context/AppContext';
import {useLanguage} from "../../../Helpers/useLanguage";

const Insurance = observer((props: ThemeProps) => {
  const state = useContext<InsuranceState>(insuranceState);
  const styles = props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  const css = conditionalStyles(styles);
  const { t, i18n } = useTranslation();
  const iframeSrcLanguage = useLanguage();

  useEffect(() => {
    app.trackTimeLoadStarted(workflow.currentStep!);
    workflow
      .loadStep(props.theme === 'pepxcite')
      .then(({language}) => {
        const globalLanguage = iframeSrcLanguage || language;
        if (!!globalLanguage) {
          i18n.changeLanguage(globalLanguage);
        }
        state.readyNow();
        app.deactivateLoader();
      })
      .then(() => app.trackTimeLoadFinished(workflow.currentStep!))
      .then(() => app.tracking.sendCheckoutStepEvent(workflow.booking, workflow.currentStepTrackingCustomEvent, workflow.getAvailableAdditionalServicesTypes))
      .then(() => {
        if (state.selectedItems.length > 0) {
          toggleItem(state.selectedItems[0]);
        } else {
          toggleItem(state.large as BookingItemInterface);
        }
      })
      .then(() => window.scrollTo(0, 0))
      .catch(e => errorHandler.catchError(e));
    // eslint-disable-next-line
  }, [state, props.theme]);

  return (
    <>
      <Breadcrumbs next={next} />
      <DefaultLayout
        top={renderTop()}
        left={renderLeft()}
        right={renderRight()}
        bottom={renderBottom()}
      />
    </>
  );

  function renderLeft() {
    return (
      <div className={styles.container}>
        <p>
          <strong>{t(Keys.WORKFLOW.Insurance.intro.title)}</strong>
        </p>
        <p>
          <Trans i18nKey={Keys.WORKFLOW.Insurance.intro.text}>
            Als zuverlässiger Versicherungspartner von { props.theme === 'pepxcite' ? <PepText text="pepXcite"/> : <PepText/>} bietet dir die HanseMerkur
            Reiseversicherung umfassenden Reiseschutz zu besonders günstigen Prämien.
          </Trans>
        </p>

        <div className={styles.insurances}>
          {state.large && (
            <Box
              item={state.large}
              isSelected={isSelected(state.large)}
              label={t(Keys.WORKFLOW.Insurance.large.label)}
              toggle={toggleItem}
              styles={styles}
            >
              <Info url={state.large.infoURL} styles={styles} />

              <div className={styles.insuranceDescription} onClick={() => toggleItem(state.large!)}>
                <h5>
                  {state.large.title}
                  <span className={styles.subhead}>{t(Keys.WORKFLOW.Insurance.large.subhead)}</span>
                </h5>

                <div className={styles.insuranceDescriptionHighlight}>
                  {t(Keys.WORKFLOW.Insurance.large.topSeller)}
                </div>

                <div className={styles.insuranceDescriptionStatic}>
                  {t(Keys.WORKFLOW.Insurance.large.description)}
                </div>

                <Collapse isOpen={isSelected(state.large)}>
                  <div
                    className={styles.insuranceDescriptionHtml}
                    dangerouslySetInnerHTML={{ __html: state.large.description }}
                  ></div>
                </Collapse>
              </div>
            </Box>
          )}

          {state.medium && (
            <Box
              item={state.medium}
              isSelected={isSelected(state.medium)}
              label={t(Keys.WORKFLOW.Insurance.medium.label)}
              toggle={toggleItem}
              styles={styles}
            >
              <Info url={state.medium.infoURL} styles={styles} />

              <div
                className={styles.insuranceDescription}
                onClick={() => toggleItem(state.medium!)}
              >
                <h5>{state.medium.title}</h5>

                <div className={styles.insuranceDescriptionStatic}>
                  {t(Keys.WORKFLOW.Insurance.medium.description)}
                </div>

                <Collapse isOpen={isSelected(state.medium)}>
                  <div
                    className={styles.insuranceDescriptionHtml}
                    dangerouslySetInnerHTML={{ __html: state.medium.description }}
                  ></div>
                </Collapse>
              </div>
            </Box>
          )}

          {props.theme === 'pepxpress' && (
            <div
              className={css('insurance', 'insurance--fullWidth', 'insurance--sub')}
              tabIndex={0}
              onClick={() => state.toogleSub()}
              onKeyPress={e => e.key === 'Enter' && state.toogleSub()}
            >
              <Info url={t(Keys.WORKFLOW.Insurance.subscription.url)} styles={styles} />

              <div className={styles.insuranceDescription}>
                <h5>{t(Keys.WORKFLOW.Insurance.subscription.headline)}</h5>
              </div>

              <div className={styles.insuranceInput}>
                <label className={styles.insuranceLabel}>
                  {t(Keys.WORKFLOW.Insurance.subscription.label)}
                </label>
              </div>

              <Collapse isOpen={state.isSubscriptionOpen}>
                <div className={styles.insuranceDescriptionHtml}>
                  <p>{t(Keys.WORKFLOW.Insurance.subscription.text1)}</p>
                  <ul>
                    <li>{t(Keys.WORKFLOW.Insurance.subscription.text2)}</li>
                    <li>{t(Keys.WORKFLOW.Insurance.subscription.text3)}</li>
                    <li>{t(Keys.WORKFLOW.Insurance.subscription.text4)}</li>
                  </ul>
                </div>
                <div className={styles.btnBox}>
                  <a
                    onClick={e => e.stopPropagation()}
                    href={t(Keys.WORKFLOW.Insurance.subscription.url)}
                    target={'_blank'}
                    className={'btn btn-outline-primary'}
                  >
                    {t(Keys.WORKFLOW.Insurance.subscription.linkText)}
                  </a>
                </div>
              </Collapse>
            </div>
          )}

          {state.none && (
            <Box
              item={state.none}
              isSelected={isSelected(state.none)}
              label={t(Keys.WORKFLOW.Insurance.nope.label)}
              toggle={toggleItem}
              styles={styles}
            >
              <div className={styles.insuranceDescription} onClick={() => toggleItem(state.none!)}>
                <h5>{t(Keys.WORKFLOW.Insurance.nope.headline)}</h5>
              </div>
            </Box>
          )}
        </div>
      </div>
    );
  }

  function renderRight() {
    return <Cart bookingOnRequest={workflow.bookingOnRequest} />;
  }

  function renderTop() {
    return (
      <div className={styles.headlineContainer}>
        <div className={styles.headlineText}>
          <Headline text={t(Keys.WORKFLOW.Insurance.headline)} />
        </div>
        <div className={styles.headlineImageContainer} title={'Hanse Merkur'}>
          <img className={styles.headlineImage} alt={'Hanse Merkur'} src={iconHanse} />
        </div>
      </div>
    );
  }

  function renderBottom() {
    return (
      <>
        <p>
          <Trans i18nKey={Keys.WORKFLOW.Insurance.angencyNote}>
            {props.theme === 'pepxcite' ? <PepText text="pepXcite"/> : <PepText/>}
            ist der vermittelnde Reiseveranstalter der Versicherung. Weitere Informationen findest
            du{' '}
            <a target={'blank'} href={props.theme === 'pepxcite'
              ? 'https://www.pepxcite.com/de/ueber-uns/impressum'
              : 'https://www.pepxpress.com/de/info/ueber-uns/impressum'}>
              hier
            </a>
            .
          </Trans>
        </p>
        <Nav onPrev={() => workflow.navigateToPrevStep()} onNext={() => next()} />
      </>
    );
  }

  async function saveStep() {
    app.trackTimeSaveStarted(workflow);
    state.updateApiErrorMessage(null);

    const payload = new PayloadInsurance();
    payload.insurances = state.selectedItems;

    await workflow.saveStep(payload);
    await app.trackTimeSaveFinished(workflow);
  }

  async function next() {
    app.activateLoader();

    if (!workflow.hasStepItems) {
      workflow.navigateToNextStep();
      return;
    }

    try {
      await saveStep();
      workflow.navigateToNextStep();
    } catch (e) {
      state.updateApiErrorMessage(e.message);
      app.deactivateLoader();
    }
  }

  async function toggleItem(item: BookingItemInterface) {
    app.activateLoader();

    state.selectedItems.forEach(i => workflow.removeItem(i.id));

    workflow.addItem<InsuranceItem>(InsuranceItem, item.id);

    try {
      await saveStep();
    } catch (e) {
      state.updateApiErrorMessage(e.message);
    }
    app.deactivateLoader();
  }

  function isSelected(item: BookingItem) {
    return state.selectedItems.filter(i => i.id === item.id).length > 0;
  }
});

export default withTheme(Insurance);
